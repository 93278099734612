<template>
  <div class="leader-detail__page">
    <div class="leader-detail-wrapper">
      <div class="leader-detail__header">
        <Header :appMenuStatusUpdate="appMenuStatusUpdate" />
      </div>
      <div class="leader-deatil">
        <LeaderDetail />
      </div>
    </div>
    <div class="leader-deatil__footer">
      <Footer />
    </div>
  </div>
</template>
<script>
import { Header, LeaderDetail, Footer } from '@/components'
export default {
  components: {
    LeaderDetail,
    Header,
    Footer
  },
  methods: {
    appMenuStatusUpdate(updatedStatus) {
      this.showSusbscribeBar = updatedStatus
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/colors';
@import '@/assets/scss/fonts';
@import '@/assets/scss/media-query-mixins';
.leader-detail__page {
  top: 0;
  .leader-detail-wrapper {
    min-height: calc(70vh);
  }
  .leader-detail__header {
    position: fixed;
    top: 0;
    width: 100%;
    background: $primary-white;
    z-index: 11;
  }
  .leader-deatil {
    margin-top: 170px;
  }
}
</style>
